import React, { FC } from 'react'

import { RouteComponentProps, StaticContext } from 'react-router'

import { OnboardingLoadable } from 'components/page/Onboarding/OnboardingLoadable'

export const OnboardingWithRedirect: FC<
  RouteComponentProps<{}, StaticContext, unknown>
> = (props) => {
  return (
    <OnboardingLoadable
      {...props}
      redirectAfterAuthPath={props.match.params[0]}
    />
  )
}

import React, { FC } from 'react'

import styled from 'styled-components'

import { PlainLink } from 'components/presentational/link'
import { NAVIGATION_LINKS } from 'components/presentational/Navigation/NavigationLinks'

export const BlogLink: FC = () => {
  const { label, to } = NAVIGATION_LINKS['blog']

  return (
    <LinkWrapper>
      <PlainLink href={to} data-name="footer-blog-action">
        {label}
      </PlainLink>
    </LinkWrapper>
  )
}

const LinkWrapper = styled.div`
  margin: 0 10px;
`

import React, { FC, PropsWithChildren } from 'react'

import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { prevStepAction } from 'actions/form/stepRegistrationWithoutApiAction'
import { DEFAULT_LOCALE, isLoveMailru } from 'common/constants'
import { Colors } from 'common-constants/colors'
import { FontSize } from 'common-constants/sizes'
import { Copyright } from 'components/block/Footer/Copyright'
import { RecommendationTechnologiesLink } from 'components/block/RecommendationTechnologiesLink/RecommendationTechnologiesLink'
import { FooterSeoLinks } from 'components/page/FooterSeoLinks/FooterSeoLinks'
import { pathsOrder } from 'components/page/StepRegistration/StepRegistration.constants'
import { NotPhone } from 'components/presentational/layout/NotPhone'
import { PhoneOnly } from 'components/presentational/layout/PhoneOnly'
import { ModalLink } from 'components/presentational/link'
import { Content, ViewContent } from 'components/presentational/modal'
import { DefaultModalContent } from 'components/presentational/modal/DefaultModalContent'
import ModalNavigation from 'components/presentational/ModalNavigation'
import { BackSvg } from 'components/presentational/svg/BackSvg'
import { useLocale } from 'hooks/useLocale'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { getLoginPath } from '../Boarding/Login.functions'
import { isShowPromoCodeDetails } from '../PromoCode/promoCode.functions'
import { PromoCodeDetailsLoadable } from '../PromoCode/PromoCodeDetailsLoadable'

export const StepContent: FC<
  {
    navigationEnabled?: boolean
    path: string
  } & PropsWithChildren
> = ({ navigationEnabled = true, path, children }) => {
  const dispatch = useDispatch()
  const locale = useLocale()
  const first = pathsOrder.indexOf(path) === 0

  const { partnerId, promocodereg } = useShallowEqualSelector(
    ({ systemReducer: { partnerId }, abTest: { promocodereg } }) => ({
      partnerId,
      promocodereg,
    })
  )

  const isShowFooterLinks = !isLoveMailru(partnerId)

  const promoCodeDetailsElement = isShowPromoCodeDetails(promocodereg) && (
    <PromoCodeDetailsLoadable />
  )

  if (first) {
    return (
      <Content>
        <NotPhone>
          <ModalNavigation />
        </NotPhone>
        <ViewContent>
          {children}
          <PhoneOnly>
            <SignInButton to={getLoginPath(partnerId)} data-name="login-action">
              <FormattedMessage id="app.enter" defaultMessage="Войти" />
            </SignInButton>
            {locale === DEFAULT_LOCALE && (
              <RecommendationTechnologiesLink
                style={{ marginTop: '30px', marginBottom: '0px' }}
              />
            )}
            <CopyrightWrapper>
              <Copyright />
            </CopyrightWrapper>
            {isShowFooterLinks && (
              <FooterSeoLinksWrapper>
                <FooterSeoLinks typeLink="mobile" />
              </FooterSeoLinksWrapper>
            )}
          </PhoneOnly>
          {promoCodeDetailsElement}
        </ViewContent>
      </Content>
    )
  }

  const handleBackClick = () => {
    dispatch(prevStepAction(path))
  }

  return (
    <DefaultModalContent
      icon={navigationEnabled ? <BackSvg /> : undefined}
      onBackClick={navigationEnabled ? handleBackClick : undefined}
      historyBack={navigationEnabled}
    >
      {children}
      {promoCodeDetailsElement}
    </DefaultModalContent>
  )
}

const SignInButton = styled(ModalLink)`
  margin-top: 20px;
  text-align: center;
`

const CopyrightWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  color: ${Colors.disabledIcon};
  font-size: ${FontSize.note}px;
  text-align: center;
`

const FooterSeoLinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${FontSize.note}px;
`

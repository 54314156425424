import React, { FC } from 'react'

import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'

import {
  cityListPath,
  shortcutListPath,
} from 'components/page/Boarding/boarding.paths'
import {
  useShowCitiesLink,
  useShowCountries,
} from 'components/page/Boarding/footer/footer.hooks'
import { SeoLink } from 'components/page/FooterSeoLinks/FooterSeoLinks.styled'
import { BlogLink } from 'components/page/Legal/Confidentiality/BlogLink'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useLayoutContext } from 'hooks/useLayoutContext'
import { useShowLinkBlog } from 'hooks/useShowLinkBlog'

export const FooterSeoLinks: FC<{ typeLink: 'mobile' | 'desktop' }> = ({
  typeLink,
}) => {
  const { baseUrl } = useLayoutContext()
  const isShowCityLink = useShowCitiesLink()
  const isShowCountries = useShowCountries()
  const hasLinkBlog = useShowLinkBlog()
  return (
    <>
      {isShowCountries && (
        <LinkWrapper>
          <SeoLink
            to={mergeAllUrls(baseUrl, shortcutListPath)}
            data-name={`footer-shortcuts-${typeLink}-action`}
          >
            <FormattedMessage
              id="boarding.footer.countries"
              defaultMessage="Страны"
            />
          </SeoLink>
        </LinkWrapper>
      )}
      {isShowCityLink && (
        <LinkWrapper>
          <SeoLink
            to={mergeAllUrls(baseUrl, cityListPath)}
            data-name={`footer-cities-${typeLink}-action`}
          >
            <FormattedMessage
              id="boarding.footer.cities"
              defaultMessage="Города"
            />
          </SeoLink>
        </LinkWrapper>
      )}
      {hasLinkBlog && <BlogLink />}
    </>
  )
}

const LinkWrapper = styled.div`
  margin: 0 10px;
`

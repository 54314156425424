import { styled } from '@linaria/react'

/**
 * Пришлось сделать не через className, потому что linaria не смогла разрулить порядок вставки стилей в местах использования:
 * Conflicting order.
 */
export const buttonsBlockCss = `
  width: 100%;
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: var(--spacing-12px);
  }
`

export const ButtonsBlock = styled.div`
  ${buttonsBlockCss}
`

import React, { FC } from 'react'

import { Switch, Route as ReactRoute, RouteComponentProps } from 'react-router'

import { AppRoute } from 'components/components.types'
import { NotFoundSwitcher } from 'components/system/NotFoundSwitcher'
import { mergeAllUrls } from 'functions/mergeAllUrls'

import { CheckConditionRoute } from './CheckConditionRoute'

interface LocaleSwitchProps {
  id?: string
  routes: AppRoute[]
  matchPath?: string
  matchUrl?: string
}

export const LocaleSwitch: FC<LocaleSwitchProps> = ({
  routes,
  matchPath,
  matchUrl,
}) => {
  return (
    <Switch>
      {routes.map(
        (
          {
            path,
            component,
            render,
            exact = true,
            authorizedOnly,
            unauthorizedOnly,
            shouldCheck,
            key,
          },
          index
        ) => {
          const newPath = mergeAllUrls(matchPath || matchUrl!, path)

          const _key = key ?? index

          if (authorizedOnly || unauthorizedOnly || shouldCheck) {
            return (
              <CheckConditionRoute
                key={_key}
                path={newPath}
                exact={exact}
                component={component as FC<RouteComponentProps>}
                render={render as FC<RouteComponentProps>}
                authorizedOnly={authorizedOnly}
                unauthorizedOnly={unauthorizedOnly}
              />
            )
          }
          return (
            <ReactRoute
              key={_key}
              path={newPath}
              exact={exact}
              component={component as FC<RouteComponentProps>}
              render={render}
            />
          )
        }
      )}
      <ReactRoute
        path={mergeAllUrls(matchPath || matchUrl!, '*')}
        component={NotFoundSwitcher}
      />
    </Switch>
  )
}

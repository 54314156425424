import React, {
  CSSProperties,
  ComponentProps,
  ReactNode,
  forwardRef,
} from 'react'

import { cx, css } from '@linaria/core'
import { styled } from '@linaria/react'

import { textOverflowStyles } from 'common/styles/textOverflow'
import { WithClassName } from 'common/types'

import { ANIMATION_DURATION_MS } from './constants'
import { ErrorText } from './ErrorText'
import { formaDJRCyrillicTextFontFamily } from '../shared/fonts/formaDJRCyrillic/formaDJRCyrillicFontFamily'
import { Typography } from '../Typography/Typography'

type InputProps = Omit<ComponentProps<typeof StyledInput>, 'size'> & {
  label?: ReactNode
  invalid?: boolean
  errorText?: ReactNode
  elementLeft?: ReactNode
  elementRight?: ReactNode
  inputInnerCss?: string
  inputCss?: string
  wrapperStyle?: CSSProperties
  inputStyle?: CSSProperties
  size?: Size
} & WithClassName

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    className,
    inputCss,
    wrapperStyle,
    inputStyle,
    inputInnerCss,
    label,
    value,
    placeholder,
    onChange,
    errorText,
    invalid,
    elementLeft,
    elementRight,
    size = 'L',
    ...restProps
  } = props

  return (
    <InputWrapper style={wrapperStyle} className={cx(className)}>
      {label && <Label fontSize={14}>{label}</Label>}
      <InputInner
        className={inputInnerCss}
        hasElementLeft={Boolean(elementLeft)}
      >
        {elementLeft && <ElementLeft>{elementLeft}</ElementLeft>}
        <StyledInput
          style={inputStyle}
          className={cx(sizeStylesMap[size], inputCss)}
          ref={ref}
          type="text"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          maxLength={500}
          aria-invalid={invalid || Boolean(errorText)}
          {...restProps}
        />
        {elementRight}
      </InputInner>
      <ErrorText errorText={errorText} />
    </InputWrapper>
  )
})

const ElementLeft = styled.div`
  position: absolute;

  html[dir='ltr'] & {
    left: 16px;
  }

  html[dir='rtl'] & {
    right: 16px;
  }

  top: 50%;
  transform: translateY(-50%);
`
const InputWrapper = styled.div`
  width: 100%;
`
const Label = styled(Typography)`
  color: var(--foreground-muted);
  padding-left: var(--spacing-6px);
  margin-bottom: var(--spacing-8px);
  ${textOverflowStyles};
`
const StyledInput = styled.input`
  width: 100%;
  margin: 0;
  border-radius: 16px;
  border: var(--spacing-2px) solid var(--border-default);
  background: var(--background-surface-1);
  color: var(--foreground-default);
  font-family: ${formaDJRCyrillicTextFontFamily};
  font-style: normal;
  transition: border-color ${ANIMATION_DURATION_MS}ms;
  caret-color: var(--mamba-default);

  /* Hide the spin buttons on number input fields in Firefox */
  appearance: textfield;

  &::placeholder {
    /* 
     * Фиксит вертикальное выравнивание плейсхолдера на iOS 13
     * https://github.com/necolas/normalize.css/issues/736#issuecomment-627474292
     */
    line-height: normal !important;
    vertical-align: middle;

    /* конец фикса */
    color: var(--foreground-muted);
    font-family: ${formaDJRCyrillicTextFontFamily};
    font-style: normal;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
    border-color: var(--border-press);
  }

  &:not(:disabled, [aria-invalid='true']):hover {
    border-color: var(--border-hover);
  }

  &[aria-invalid='true'] {
    border-color: var(--error-default);
  }
`

const PADDING_X = 12

const InputInner = styled.div<{ hasElementLeft: boolean }>`
  position: relative;

  ${StyledInput} {
    html[dir='ltr'] & {
      padding-left: ${({ hasElementLeft }) =>
        hasElementLeft ? '40px' : `${PADDING_X}px`};
    }

    html[dir='rtl'] & {
      padding-right: ${({ hasElementLeft }) =>
        hasElementLeft ? '40px' : `${PADDING_X}px`};
    }
  }
`

type Size = 'L' | 'M'

const sizeStylesMap: Record<Size, string> = {
  L: css`
    font-size: 24px;
    line-height: 24px;
    padding: 10px ${PADDING_X}px;
    letter-spacing: 0.32px;
    font-weight: 500;

    &::placeholder {
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.32px;
      font-weight: 500;
    }
  `,
  M: css`
    font-size: 16px;
    line-height: 16px;
    padding: 10px ${PADDING_X}px;
    letter-spacing: 0.48px;
    font-weight: 400;

    &::placeholder {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.48px;
      font-weight: 400;
    }
  `,
}
